import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Lock from "./lock";
import Key from "./key";
import Loading from "./loading";
import Info from "./info";


const ResetPassword = () => {
    const navigate = useNavigate();
    const { email } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [text, setText] = useState(`Click the link sent to ${email}`);

    const startResetPasswordProcess = async () => {
        if (requestLifeCycle) {
            return;
        }
        
        const url = process.env.REACT_APP_USER_API_URL + "/create-reset-password";

        const urlOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email: email })
        }

        try {
            setRequestLifeCycle(true);
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            console.log(data);
            if (res.status === 200) {
                setText("Reset password email resent")
            } else if (res.status === 404) {
                setText("User not found");
                navigate("/sign-up")
            } else if (res.status === 400) {
                setText("Email address is not verified");
                navigate("/verify-email");
            } else if (res.status === 409) {
                setText("Please wait 15 minutes for email resend cooldown");
            } else {
                setText("Reset password email failed to resend");
            }

        } catch (error) {
            setText("An error occurred")
            console.log(error)
        } finally {
            setRequestLifeCycle(false);
        }
    }

    return (
        <>
            <div className="signup-container">
                <div className="add-a-course-form-container">
                    <div className="add-a-course-form-content-container">
                        <form className="form-width">
                            <div className="add-course-title">
                                Password Reset In Progress
                            </div>
                            <div className="account-settings-content">
                                <div className="required-container">
                                    See Inbox For Details
                                </div>   
                                <div className="banner top">
                                    {
                                        requestLifeCycle

                                        ?

                                        <>
                                            <Loading />
                                        </>

                                        :

                                        <div className="banner-info">
                                            <Info />
                                            { text }
                                        </div>

                                    }
                                </div>
                                <div className="gray-svg-container">
                                    <Lock checked={false}/>
                                    <Key checked={false}/>
                                </div>
                                <div className="forgot-verify" style={{ justifyContent: "center", textDecoration: "underline" }}>
                                    <span className="actions" onClick={() => {
                                        startResetPasswordProcess();
                                    }} style={{ color: "var(--almost-black)" }}> Resend email </span>
                                </div>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;