import React, { useState, useEffect } from "react";

const Support = ({ supportRef }) => {
    const [text, setText] = useState("Send");
    const [sent, setSent] = useState(false);
    const [form, setForm] = useState({
        email: "",
        question: ""
    })

    const submitForm = async () => {
        if (text === "Sending") {
            return;
        }

        if (sent) {
            return;
        }
        
        if (!form.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setText("Invalid Email");
            return;
        }

        if (!form.question) {
            setText("Invalid Question")
        }

        if (form.question.length > 1000) {
            setText("Question Too Long")
        }

        try {
            setText("Sending")
            const url = process.env.REACT_APP_USER_API_URL + "/support"
            const urlOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(form)           
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json()
            if (response.status === 200) {
                setText("Success");
                setSent(true);
            } else {
                setText("Failed")
            }

        } catch (error) {
            setText("Failed")
            console.log(error)
        }
    }

    useEffect(() => {
        setText("Send")
    }, [form])

    return (
        <>
            <div className="support-container" ref={supportRef}>
                {
                    text === "Success" ?

                    <>
                    </>

                    :

                    <>
                        <input className="support-item" placeholder="your-email@example.com" value={form.email} onChange={(e) => setForm((prev) => ({...prev, email: e.target.value}))}/>
                        <textarea className="support-item" placeholder="Insert your question here." value={form.question} onChange={(e) => setForm((prev) => ({...prev, question: e.target.value}))} style={{ minHeight: "180px" }}/>
                    </>
                    
                }                
                <div className="send" onClick={() => submitForm()}>
                    { text }
                </div>
            </div>
        </>
    );
}

export default Support;